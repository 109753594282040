.overlay {
    position: absolute; /* Position overlay on top */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 20px;
    z-index: 10; /* Ensure it is above other content */
}