.custom-active-tab .nav-link.active {
    background-color: transparent; /* Example color */
    color: #FFF5EB; /* Example text color */
    border-color: transparent; /* Example border color */
    border-bottom-color: #1D8939;
}

.custom-active-tab .nav-link {
    background-color: transparent; /* Example color */
    color: #5A5C5F; /* Example text color */
    border-color: transparent; /* Example border color */
}


