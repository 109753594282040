.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #5A5C5F;
    opacity: 1; /* Firefox */

}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #5A5C5F;
}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
    color: #5A5C5F;
}