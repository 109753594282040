/*!* Custom CSS for Accordion *!*/
.custom-accordion {
    background-color: #171717;
    border: 1px solid #0E5B22;
    box-shadow: none;
}

.custom-accordion-item {
    background-color: #171717;
    color: white;
    border: none;
    box-shadow: none;
}

.custom-accordion-header {
    background-color: #171717; /* Background color for headers */
    box-shadow: none;
}

.accordion-button:hover {
    border: none; /* Remove border */
    box-shadow: none; /* Optionally remove shadow if it exists */
}

/* Alternatively, if you want to just remove the border color */
.accordion-button:hover {
    border-color: transparent; /* Make border invisible */
    box-shadow: none;
}

.accordion-button {
    color: #ffffff;
    background-color: #171717;
    box-shadow: none;

}
.accordion-button:not(.collapsed) {
    color: #ffffff;
    background-color: #171717;
    box-shadow: none;
    border-color: transparent;
}



