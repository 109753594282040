/* Customize the hover color for menu items */
.pro-sidebar .pro-menu-item:hover {
    background-color: #9c1b1b; /* Change background color on hover */
    color: #ffffff;            /* Change text color on hover */
}

/* Customize hover color for SubMenu items */
.pro-sidebar .pro-menu-item .pro-inner-list-item:hover {
    background-color: #b10d0d;
    color: #ffffff;
}
