.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



/* Global scrollbar style for WebKit browsers (Chrome, Safari, Edge) */
html, body {
  scrollbar-color: #0E5B22 #D9D9D9; /* For Firefox: thumb color, track color */
  scrollbar-width: thin;
  border-radius: 0;/* For Firefox: thin scrollbar */
}


html::-webkit-scrollbar {
  width: 5px; /* Reduced width of the scrollbar */
  height: 5px; /* If horizontal scrollbar is needed, reduce its height too */

}

html::-webkit-scrollbar-track {
  background: #D9D9D9;  /* Track color */
}

html::-webkit-scrollbar-thumb {
  background-color: #0E5B22;  /* Thumb color */
  border-radius: 0;     /* Rounded corners for thumb */
  border: 3px solid #0E5B22;  /* Padding around thumb */
}

html::-webkit-scrollbar-thumb:hover {
  background-color: #D9D9D9;  /* Thumb color on hover */
}
