.file-upload-box {
    border: 2px dashed #ccc;
    padding: 20px;
    width: 100%;
    height: 100px;
    text-align: center;
    cursor: pointer;
    border-radius: 10px;
    transition: border-color 0.3s ease;
}

.file-upload-box:hover {
    border-color: #CBD0DC;
}

.file-input {
    display: none;
}

.file-label {
    font-size: 16px;
    color: #292D32;
    display: block;
    cursor: pointer;
}

.file-upload-box.active {
    border-color: #CBD0DC;
    background-color: #CBD0DC;
}
